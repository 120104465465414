import React from "react";
import { Row, Col, Label, Spinner, Badge, Button,Card } from "reactstrap";
import { useLoggedIn } from "../../../Components/Hooks/useLoggedIn";
import { authProvider } from "../../../Components/Hooks/authProvider";
import { useState } from "react";
import DeleteSubModal from "./ApplicationsTab/DeleteSubModal";
import { useEffect } from "react";
import StripeSuscriptionModal from './../../Purchases/Stripe/StripeSuscriptionModal'

const BillingTab = () => {
  const { user } = useLoggedIn();
  const { phones, useSubsQuery } = authProvider();

  const [phone, setPhone] = useState(null);
  const { data: subs, isLoading } = useSubsQuery(phone);

  const [sub, setSub] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const handleOpen = () => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    if (user) {
      setPhone(user?.msisdn);
    }
  }, [user]);

  const toggleOpenModal = (sub) => {
    setSub(sub);
    setModalVisible(true);
  };
  const toggleCloseModal = () => {
    setModalVisible(false);
  };

  const intervalES = (interval) => {
    const translations = {
      'day': 'día(s)',
      'week': 'semana(s)',
      'month': 'mes(es)',
      'year': 'año(s)',
  };
  const lowerCaseInterval = interval.toLowerCase();
  return translations[lowerCaseInterval] || '';
  };

  return (
    <React.Fragment>
      <Row className="justify-content-center">
        <Col xs={10} sm={8}>
          <Label className="text-primary">Número</Label>
          <select
            className="form-select"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          >
            { user.msisdn && <option value={user.msisdn}>Tú {user.name+' '+user.last_name+' ('+user.msisdn+')'}</option> }
            { phones && phones.map((e, index) => (
              <option key={'number-billing'+index} value={e.msisdn}>{e.name+' '+e.last_name+' ('+e.msisdn+')'}</option>
            ))}
          </select>
        </Col>

        { isLoading && <Spinner className="text-primary" /> }
        { !isLoading && subs?.length === 0 &&
        <Row className="justify-content-center">
          <Col className="col-12 py-1 text-center">
            <p>Sin suscripciones</p>
          </Col>
          <Col className="col-12 py-1">
            <Button
                  color="primary"
                  outline
                  className="w-100 my-4"
                  onClick={handleOpen}
                >
                  Crear suscripcion
              </Button>
          </Col>
          <Col>
              <Card
              className="text-center cursor-pointer"
              style={{ width: "330px", borderRadius: "30px" }}>
              <StripeSuscriptionModal
                isOpen={isOpen}
                onToggle={handleOpen}
                phone={phone}
              />
            </Card>
          </Col>
        </Row>
        }

        { !isLoading && subs?.length > 0 &&
          <Col xs={10} sm={8} md={12} className="mt-4">
            <Row className="text-primary mb-4">Lista de suscripciones</Row>
              { subs.map((sub, index) => (
                <table key={'table-billing-'+index} className="table align-middle table-borderless table-centered table-nowrap mb-0">
                  <thead className="text-muted table-light">
                    <tr>
                      <th>ID</th>
                      <th className="text-center">Estado</th>
                      <th className="text-center">Precio</th>
                      <th className="text-center">Intervalo</th>
                      <th className="text-center">Comienza</th>
                      <th className="text-center">Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{sub.id}</td>
                      <td align="center">
                        { sub?.status === 'active' && <Badge color="success">Activa</Badge>}
                        { sub?.status !== 'active' && <Badge color="secondary">Desactivada</Badge>}
                        </td>
                      <td align="center">
                        ${parseFloat(sub.plan.amount / 100).toLocaleString("en-US", {
                          maximumFractionDigits: 2,
                        })}
                      </td>
                      <td align="center">
                        {sub.plan.interval_count + " " + intervalES(sub.plan.interval)}
                      </td>
                      <td align="center">{ new Date(sub.current_period_start * 1000).toLocaleString() }</td>
                      <td align="center">
                        { sub?.status === 'active' &&
                          <button
                            className="btn btn-primary"
                            onClick={() => {
                              toggleOpenModal(sub);
                            }}
                          >
                            Cancelar Suscripción
                          </button>
                        }
                      </td>
                    </tr>
                  </tbody>
                </table>
                ))
              }
          </Col>
        }
      </Row>
      <DeleteSubModal
        isOpen={modalVisible}
        onClose={toggleCloseModal}
        sub={sub}
        msisdn={phone}
      />
    </React.Fragment>
  );
};

export default BillingTab;
