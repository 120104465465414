import React from "react";
import { Col, Container, Row } from "reactstrap";

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid>
          <Row>
            <Col sm={6}>{new Date().getFullYear()} © Infynit.</Col>
            <Col sm={6}>
              <p className="text-black text-center">
                Consulta términos y condiciones en <a className="underline"
                                                      href="http://www.infynit.mx">www.infynit.mx</a>
              </p>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
