import React, { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { CheckoutForm } from './CheckoutForm';
import { config } from '../../../config/config';
import { usePayments } from '../usePayments';

const StripePayment = ({ msisdn, productId }) => {
  const { usePayStripe } = usePayments();
  const { mutate: payStripe, isPending: isLoading } = usePayStripe; // Mutate para ejecutar la función

  const stripePromise = loadStripe(`${config.STRIPE.PUBLIC_KEY}`);
  const [clientSecret, setClientSecret] = useState('');
  const [dpmCheckerLink, setDpmCheckerLink] = useState('');

  useEffect(() => {
    fetchClientSecret();
  }, []);

  const fetchClientSecret = async () => {
    payStripe(
      { productId, msisdn },
      {
        onSuccess: (data) => {
          setClientSecret(data.paymentIntent);
          setDpmCheckerLink(data.dpmCheckerLink);
        },
        onError: (error) => {
          console.error('Error al obtener el clientSecret:', error);
        },
      }
    );
  };

  const appearance = {
    theme: 'stripe',
  };

  const loader = 'auto';

  return (
    <>

      {isLoading && <div className="d-flex justify-content-center">
      <div className="input-spinner" id="spinner"></div>
    </div>}
      {!isLoading && clientSecret && stripePromise && (
        <Elements stripe={stripePromise} options={{ clientSecret, appearance, loader }}>
          <CheckoutForm dpmCheckerLink={dpmCheckerLink} />
        </Elements>
      )}
    </>
  );
};

export default StripePayment;
