import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { loginSuccess, apiError } from "../../slices/auth/login/reducer";
import { TOKEN_KEY, USER_KEY, setAuthorization } from "../../helpers/api_helper";
import axios from "axios";
import {config} from "../../config/config"
import { useNavigate } from "react-router-dom";
import { errorAlert } from "../../helpers/alerts";

export const useLogin = (navigation) => {
  const [userLogin, setUserLogin] = useState({ user: "", password: "" });
  const [passwordShow, setPasswordShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loginForm = useFormik({
    initialValues: {
      user: userLogin.user,
      password: userLogin.password,
    },
    validationSchema: Yup.object({
      user: Yup.string().required("Por favor ingrese su usuario"),
      password: Yup.string().required("Por favor ingrese su contraseña"),
    }),
    onSubmit: (user) => {
      loginUser(user, navigation);
    },
  });

  const loginUser = async (user) => {
    setLoading(true);
    try {
      const response = await axios.post(`${config.BACKEND.URL}/user/login`, {
        user: user.user,
        password: user.password,
      });

      if (response) {
        if (response?.data?.token) {
          const token = response.data.token;
          sessionStorage.setItem(TOKEN_KEY, token);

          dispatch(loginSuccess());
          setAuthorization(token);
          setLoading(false);
          navigate('/');
        }
      }
    } catch (error) {
      dispatch(apiError(error));
      errorAlert("Ocurrio un error", "Revisa los datos ingresados");
      setLoading(false);
    }
  };

  return { loginForm, passwordShow, setPasswordShow, loading };
};
