import React, { useState } from "react";
import {
	PaymentElement,
	useStripe,
	useElements
} from "@stripe/react-stripe-js";

const CheckoutForm = ({ dpmCheckerLink }) => {
	const stripe = useStripe();
	const elements = useElements();

	const [message, setMessage] = useState(null);
	const [isLoading, setIsLoading] = useState(false);

	const handleSubmit = async (e) => {
		e.preventDefault();

		if (!stripe || !elements) {
			// Stripe.js hasn't yet loaded.
			// Make sure to disable form submission until Stripe.js has loaded.
			return;
		}

		setIsLoading(true);

		const { error } = await stripe.confirmPayment({
			elements,
			confirmParams: {
				// Make sure to change this to your payment completion page
				return_url: `${window.location.origin}/stripe_success`,
			},
		});

		// This point will only be reached if there is an immediate error when
		// confirming the payment. Otherwise, your customer will be redirected to
		// your `return_url`. For some payment methods like iDEAL, your customer will
		// be redirected to an intermediate site first to authorize the payment, then
		// redirected to the `return_url`.
		if (error.type === "card_error" || error.type === "validation_error") {
			setMessage(error.message);
		} else {
			setMessage("An unexpected error occurred.");
		}

		setIsLoading(false);
	};

	const paymentElementOptions = {
		layout: "accordion"
	}
	return (
		<>
      {message && (<p>{message}</p>)}
			<form id="payment-form" onSubmit={handleSubmit}>

				<PaymentElement id="payment-element" options={paymentElementOptions} />
        {isLoading ? <p className="text-black">Relizando cobro...</p>:null}
				<button disabled={isLoading || !stripe || !elements} id="submit"
                className="px-4 py-2 cursor-pointer"
                style={{
                  background: "#a8e5ff",
                  borderRadius: "4px",
                  border: "none",
                }}
        >
					<span id="button-text">
						{isLoading ? <div className="input-spinner mx-10" id="spinner"></div>: "Pagar ahora"}
					</span>
				</button>
				{message && <div id="payment-message">{message}</div>}
			</form>
		</>
	);
}
export { CheckoutForm }
