import { useEffect, useState } from "react";
import { VictoryPie, VictoryTooltip } from "victory";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Progress,
  Row,
  Spinner,
  Table,
  Button,
} from "reactstrap";
import useGetStatus from "../../Components/Hooks/useMetrics";
import { useSwipeable } from "react-swipeable";

const Statistics = ({ msidsn }) => {
  const {
    useGaugeDataNational,
    useGaugeDataInternational,
    useTotalNational,
    useTotalInternational,
    useIsMovilidad,
    useNationalCards,
    useInternationalCards,
  } = useGetStatus(msidsn);
  const {
    data: gaugeDataNational,
    isError: isErrorNational,
    isLoading: isFetchingNational,
  } = useGaugeDataNational;

  const { data: nationalCards } = useNationalCards;
  const { data: internationalCards } = useInternationalCards;
  const { data: totalRemainingNational } = useTotalNational;

  const { data: gaugeDataInternational, isLoading: isFetchingInternational } =
    useGaugeDataInternational;
  const { data: totalRemainingInternational } = useTotalInternational;
  const { data: isMovilidad } = useIsMovilidad;
  const [storedGaugeDataNational, setStoredGaugeDataNational] =
    useState(gaugeDataNational);
  const [storedGaugeDataInternational, setStoredGaugeDataInternational] =
    useState(gaugeDataInternational);
  const [visibleGauge, setVisibleGauge] = useState("national");
  useEffect(() => {
    if (gaugeDataNational) {
      setStoredGaugeDataNational(gaugeDataNational);
    } else {
      setStoredGaugeDataNational([]);
    }
    if (gaugeDataInternational) {
      setStoredGaugeDataInternational(gaugeDataInternational);
    } else {
      setStoredGaugeDataInternational([]);
    }
  }, [gaugeDataNational, gaugeDataInternational]);

  function formatDate(date) {
    const months = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ];

    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    const formattedDate = `${day} de ${month} del ${year}`;
    return formattedDate;
  }

  const toggleMetric = (index, type) => {
    const toggledMetrics =
      type === "national"
        ? storedGaugeDataNational.map((data, i) =>
            index === i ? { ...data, active: !data.active } : data
          )
        : storedGaugeDataInternational.map((data, i) =>
            index === i ? { ...data, active: !data.active } : data
          );

    if (type === "national") {
      setStoredGaugeDataNational(toggledMetrics);
    } else {
      setStoredGaugeDataInternational(toggledMetrics);
    }
  };

  const ProgressBarDetail = ({ text, total, value, color }) => {
    const [isPercentageShown, setIsPercentageShown] = useState(false);

    let percentageText;
    if (total === 0) {
      percentageText = "0%";
    } else {
      const percentage = (value / total) * 100;
      percentageText =
        (Number.isInteger(percentage)
          ? percentage.toString()
          : percentage.toFixed(2)) + "%";
    }

    return (
      <Progress
        bar
        min={0}
        max={total}
        value={value}
        style={{ backgroundColor: color }}
        onMouseOver={() => setIsPercentageShown(true)}
        onMouseOut={() => setIsPercentageShown(false)}
        onClick={() => setIsPercentageShown(!isPercentageShown)}
      >
        {isPercentageShown ? percentageText : text}
      </Progress>
    );
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => setVisibleGauge("international"),
    onSwipedRight: () => setVisibleGauge("national"),
  });

  const MetricButton = ({ metric, index, toggleMetric }) => (
    <button
      key={index}
      onClick={() => toggleMetric(index, "national")}
      style={{
        paddingRight: 21,
        paddingTop: 5,
        alignItems: "center",
        background: "transparent",
        border: "none",
      }}
    >
      <div className="d-flex justify-content-center">
        <div
          style={{
            backgroundColor: metric.fill,
            width: 15,
            height: 15,
            marginRight: 10,
          }}
        ></div>
        <p
          style={{
            fontSize: 11,
            textDecorationLine: metric.active ? "none" : "line-through",
            textDecorationStyle: "solid",
            background: "none",
          }}
        >
          {metric.name}
        </p>
      </div>
    </button>
  );

  const storedGaugeData = (stored) => (
    <>
      {stored.map((metric, index) => (
        <MetricButton
          key={index}
          metric={metric}
          index={index}
          toggleMetric={toggleMetric}
        />
      ))}
    </>
  );

  const renderVictoryPieAdjusted = (data) =>
    renderVictoryPie(
      data.map((d) => ({
        ...d,
        amount: d.amount === 0 ? 0.01 : d.amount, // Evitar valores de 0
      }))
    );

  const renderVictoryPie = (data) => (
    <svg viewBox="0 50 400 150" className="img-fluid">
      <VictoryPie
        standalone={false}
        startAngle={90}
        endAngle={-90}
        padAngle={0.8}
        data={data.filter((data) => data.active)}
        x="name"
        y="amount"
        innerRadius={70}
        labelRadius={100}
        labelComponent={
          <VictoryTooltip
            renderInPortal={false}
            style={{ fill: "white", fontSize: 8 }}
            flyoutStyle={{
              fill: "rgba(52, 52, 52, 0.8)",
            }}
            animate={{ duration: 500, onLoad: { duration: 500 } }}
          />
        }
        sortOrder="descending"
        sortKey="index"
        events={[
          {
            target: "data",
            eventHandlers: {
              onMouseLeave: () => {
                return [
                  {
                    target: "labels",
                    eventKey: "all",
                    mutation: () => ({ active: false }),
                  },
                ];
              },
              onMouseEnter: () => {
                return [
                  {
                    target: "labels",
                    mutation: () => ({ active: true }),
                  },
                  {},
                ];
              },
            },
          },
        ]}
        style={{
          labels: { fill: "black" },
          data: { fill: ({ datum }) => datum.fill },
        }}
      />
    </svg>
  );

  const drawCard = (card, isNational) => (
    <Row className="d-flex justify-content-center pt-4">
      <Col xl={6}>
        {card.map((detail, index) => (
          <Card key={"detail-" + index}>
            <CardHeader className="pb-0">
              <h6 className="text-center">{detail.title}</h6>
              <Table borderless size="sm">
                <tbody>
                  <tr>
                    <td>Inicia el:</td>
                    <td align="right">{formatDate(detail.startsAt)}</td>
                  </tr>
                  <tr>
                    <td>Termina el:</td>
                    <td align="right">{formatDate(detail.endsAt)}</td>
                  </tr>
                </tbody>
              </Table>
            </CardHeader>
            <CardBody>
              {detail.lines.map((line, detailIndex) => (
                <Row key={`${index}${detailIndex}`}>
                  {line.msg != "" && (
                    <Col xs={12} md={12} className="  text-center">
                      {line.msg == "Redes Sociales Ilimitadas" ? (
                        <Row className="justify-content-center mb-3">
                          <Col className="text-center">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              x="0px"
                              y="0px"
                              width="35"
                              height="35"
                              viewBox="0 0 48 48"
                            >
                              <linearGradient
                                id="k87TA_gnBJ8uBlK4qfs8ia_AltfLkFSP7XN_gr1"
                                x1="6.718"
                                x2="35.097"
                                y1="12.801"
                                y2="41.18"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop offset="0" stopColor="#dfe9f2"></stop>
                                <stop offset=".391" stopColor="#d6e0e9"></stop>
                                <stop offset="1" stopColor="#bfc8d1"></stop>
                              </linearGradient>
                              <path
                                fill="url(#k87TA_gnBJ8uBlK4qfs8ia_AltfLkFSP7XN_gr1)"
                                d="M37.848,9.86C34.073,6.083,29.052,4.002,23.709,4C12.693,4,3.727,12.962,3.722,23.979	c-0.001,3.367,0.849,6.685,2.461,9.622L3.598,43.04c-0.094,0.345,0.002,0.713,0.254,0.967c0.19,0.192,0.447,0.297,0.711,0.297	c0.085,0,0.17-0.011,0.254-0.033l9.687-2.54c2.828,1.468,5.998,2.243,9.197,2.244c11.024,0,19.99-8.963,19.995-19.98	C43.698,18.656,41.621,13.636,37.848,9.86z"
                              ></path>
                              <linearGradient
                                id="k87TA_gnBJ8uBlK4qfs8ib_AltfLkFSP7XN_gr2"
                                x1="15.389"
                                x2="28.863"
                                y1="10.726"
                                y2="39.265"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop offset="0" stopColor="#2ecc75"></stop>
                                <stop offset="1" stopColor="#0b964a"></stop>
                              </linearGradient>
                              <path
                                fill="url(#k87TA_gnBJ8uBlK4qfs8ib_AltfLkFSP7XN_gr2)"
                                d="M34.871,12.832c-2.98-2.982-6.941-4.625-11.157-4.626c-8.704,0-15.783,7.076-15.787,15.774	c-0.001,2.981,0.833,5.883,2.413,8.396l0.376,0.597l-1.595,5.821l5.973-1.566l0.577,0.342c2.422,1.438,5.2,2.198,8.032,2.199h0.006	c8.698,0,15.777-7.077,15.78-15.776C39.49,19.778,37.851,15.814,34.871,12.832z"
                              ></path>
                              <path
                                d="M28.893,33.879c-0.995,0-2.354-0.254-5.087-1.331c-3.06-1.208-6.066-3.83-8.464-7.384l-0.077-0.113	c-0.642-0.857-2.132-3.107-2.132-5.5c0-2.58,1.288-3.953,1.838-4.54l0.085-0.091C15.815,14.089,16.709,14,17.058,14	c0.369,0.004,0.682,0,0.953,0.012c0.654,0.026,1.399,0.215,1.936,1.409l0,0c0.25,0.558,0.676,1.605,1.009,2.426	c0.213,0.527,0.386,0.955,0.439,1.069c0.294,0.586,0.308,1.167,0.036,1.714l-0.065,0.133c-0.128,0.262-0.261,0.533-0.544,0.863	l-0.235,0.282c-0.162,0.197-0.325,0.393-0.47,0.545c0.389,0.641,1.206,1.856,2.331,2.86c1.394,1.241,2.588,1.76,3.229,2.039	c0.127,0.055,0.233,0.102,0.317,0.142c0.405-0.47,1.072-1.271,1.302-1.614c0.77-1.156,1.877-0.755,2.24-0.622	c0.569,0.206,3.323,1.576,3.35,1.589l0.255,0.125c0.419,0.203,0.813,0.394,1.062,0.808c0.395,0.661,0.176,2.073-0.193,3.105	c-0.534,1.503-2.828,2.805-4.054,2.915l-0.226,0.024C29.465,33.855,29.196,33.879,28.893,33.879z M17.216,16	c-0.14,0-0.385-0.058-0.686,0.27l-0.101,0.109c-0.453,0.483-1.297,1.383-1.297,3.172c0,1.843,1.326,3.757,1.732,4.3	c0.027,0.036,0.071,0.101,0.135,0.194c2.175,3.223,4.853,5.582,7.541,6.642c3.384,1.335,4.253,1.234,4.956,1.151l0.278-0.03	c0.609-0.055,2.122-0.951,2.351-1.594c0.209-0.585,0.276-1.087,0.287-1.374c-0.044-0.021-0.092-0.043-0.143-0.067l-0.283-0.139	c-0.637-0.32-2.779-1.366-3.131-1.495c-0.442,0.608-1.262,1.565-1.479,1.814c-0.407,0.467-1.127,0.909-2.229,0.354	c-0.066-0.033-0.156-0.071-0.268-0.12c-0.691-0.301-2.13-0.926-3.763-2.38c-1.469-1.311-2.474-2.904-2.838-3.529	c-0.445-0.761-0.322-1.495,0.366-2.18c0.12-0.12,0.257-0.291,0.397-0.46l0.262-0.314c0.118-0.137,0.161-0.226,0.267-0.441	l0.035-0.071c-0.092-0.204-0.278-0.659-0.502-1.213c-0.323-0.797-0.736-1.815-0.979-2.357v0c-0.065-0.144-0.114-0.215-0.138-0.245	c0.005,0.015-0.029,0.016-0.058,0.014C17.706,16,17.463,16,17.216,16z M32.407,28.615L32.407,28.615L32.407,28.615z M19.642,19.736	L19.642,19.736L19.642,19.736z"
                                opacity=".05"
                              ></path>
                              <path
                                d="M28.889,33.384c-0.846,0-2.155-0.22-4.899-1.302c-2.967-1.17-5.891-3.727-8.233-7.198l-0.087-0.128	c-0.616-0.822-2.037-2.962-2.037-5.206c0-2.382,1.193-3.654,1.703-4.198l0.089-0.096c0.625-0.683,1.351-0.756,1.634-0.756	c0.377,0.003,0.667,0,0.931,0.012c0.492,0.02,1.057,0.124,1.502,1.114l0,0c0.249,0.554,0.671,1.594,1.001,2.409	c0.225,0.555,0.405,1.002,0.452,1.097c0.082,0.165,0.338,0.674,0.039,1.275l-0.067,0.136c-0.125,0.255-0.233,0.476-0.475,0.758	L20.2,21.59c-0.173,0.21-0.346,0.419-0.496,0.569c-0.216,0.215-0.216,0.215-0.13,0.362c0.328,0.563,1.232,1.998,2.541,3.165	c1.453,1.295,2.696,1.834,3.363,2.124c0.144,0.062,0.259,0.113,0.344,0.156c0.293,0.146,0.323,0.116,0.427-0.002	c0.288-0.328,1.168-1.364,1.463-1.807c0.554-0.83,1.269-0.57,1.654-0.431c0.506,0.184,3.039,1.437,3.296,1.566l0.262,0.128	c0.38,0.184,0.68,0.329,0.852,0.614c0.254,0.426,0.149,1.603-0.235,2.681c-0.488,1.371-2.646,2.497-3.628,2.585l-0.239,0.026	C29.441,33.354,29.196,33.384,28.889,33.384z M17.201,15.5c-0.026,0-0.052,0-0.078,0c-0.183,0-0.595,0.031-0.962,0.432l-0.097,0.104	c-0.465,0.496-1.432,1.528-1.432,3.514c0,1.943,1.281,3.864,1.832,4.6c0.025,0.033,0.064,0.09,0.121,0.174	c2.231,3.306,4.991,5.73,7.772,6.828c3.505,1.382,4.445,1.271,5.197,1.183l0.267-0.029c0.693-0.062,2.451-1.013,2.776-1.925	c0.333-0.932,0.347-1.71,0.296-1.877c0.007,0.006-0.232-0.098-0.405-0.182l-0.276-0.136c-0.623-0.313-2.806-1.381-3.188-1.52	c-0.36-0.13-0.361-0.133-0.48,0.046c-0.349,0.521-1.32,1.657-1.542,1.91c-0.642,0.735-1.384,0.359-1.629,0.236	c-0.072-0.036-0.171-0.078-0.293-0.131c-0.668-0.291-2.057-0.895-3.63-2.296c-1.416-1.262-2.387-2.803-2.739-3.407	c-0.476-0.814,0.059-1.347,0.287-1.574c0.13-0.13,0.28-0.313,0.431-0.497l0.255-0.306c0.159-0.186,0.226-0.322,0.336-0.547	l0.07-0.143c0.049-0.098,0.058-0.189-0.04-0.383c-0.052-0.104-0.245-0.578-0.483-1.167c-0.326-0.803-0.741-1.829-0.987-2.374l0,0	c-0.229-0.509-0.363-0.515-0.632-0.525C17.717,15.5,17.461,15.5,17.201,15.5z"
                                opacity=".07"
                              ></path>
                              <path
                                fill="#fff"
                                fillRule="evenodd"
                                d="M19.035,15.831c-0.355-0.79-0.729-0.806-1.068-0.82	C17.69,14.999,17.374,15,17.058,15s-0.83,0.119-1.265,0.594c-0.435,0.475-1.661,1.622-1.661,3.956s1.7,4.59,1.937,4.906	c0.237,0.316,3.282,5.259,8.104,7.161c4.007,1.58,4.823,1.266,5.693,1.187c0.87-0.079,2.807-1.147,3.202-2.255	c0.395-1.108,0.395-2.057,0.277-2.255c-0.119-0.198-0.435-0.316-0.909-0.554s-2.807-1.385-3.242-1.543	c-0.435-0.158-0.751-0.237-1.068,0.238c-0.316,0.474-1.225,1.543-1.502,1.859c-0.277,0.317-0.554,0.357-1.028,0.119	c-0.474-0.238-2.002-0.738-3.815-2.354c-1.41-1.257-2.362-2.81-2.639-3.285c-0.277-0.474-0.03-0.731,0.208-0.968	c0.213-0.213,0.474-0.554,0.712-0.831c0.237-0.277,0.316-0.475,0.474-0.791c0.158-0.317,0.079-0.594-0.04-0.831	C20.379,19.115,19.457,16.769,19.035,15.831z"
                                clipRule="evenodd"
                              ></path>
                            </svg>
                          </Col>
                          <Col className="text-center">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              x="0px"
                              y="0px"
                              width="35"
                              height="35"
                              viewBox="0 0 48 48"
                            >
                              <linearGradient
                                id="Ld6sqrtcxMyckEl6xeDdMa_uLWV5A9vXIPu_gr1"
                                x1="9.993"
                                x2="40.615"
                                y1="9.993"
                                y2="40.615"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop offset="0" stopColor="#2aa4f4"></stop>
                                <stop offset="1" stopColor="#007ad9"></stop>
                              </linearGradient>
                              <path
                                fill="url(#Ld6sqrtcxMyckEl6xeDdMa_uLWV5A9vXIPu_gr1)"
                                d="M24,4C12.954,4,4,12.954,4,24s8.954,20,20,20s20-8.954,20-20S35.046,4,24,4z"
                              ></path>
                              <path
                                fill="#fff"
                                d="M26.707,29.301h5.176l0.813-5.258h-5.989v-2.874c0-2.184,0.714-4.121,2.757-4.121h3.283V12.46 c-0.577-0.078-1.797-0.248-4.102-0.248c-4.814,0-7.636,2.542-7.636,8.334v3.498H16.06v5.258h4.948v14.452 C21.988,43.9,22.981,44,24,44c0.921,0,1.82-0.084,2.707-0.204V29.301z"
                              ></path>
                            </svg>
                          </Col>
                          <Col className="text-center">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              x="0px"
                              y="0px"
                              width="35"
                              height="35"
                              viewBox="0 0 48 48"
                            >
                              <radialGradient
                                id="8O3wK6b5ASW2Wn6hRCB5xa_YFbzdUk7Q3F8_gr1"
                                cx="11.087"
                                cy="7.022"
                                r="47.612"
                                gradientTransform="matrix(1 0 0 -1 0 50)"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop offset="0" stopColor="#1292ff"></stop>
                                <stop offset=".079" stopColor="#2982ff"></stop>
                                <stop offset=".23" stopColor="#4e69ff"></stop>
                                <stop offset=".351" stopColor="#6559ff"></stop>
                                <stop offset=".428" stopColor="#6d53ff"></stop>
                                <stop offset=".754" stopColor="#df47aa"></stop>
                                <stop offset=".946" stopColor="#ff6257"></stop>
                              </radialGradient>
                              <path
                                fill="url(#8O3wK6b5ASW2Wn6hRCB5xa_YFbzdUk7Q3F8_gr1)"
                                d="M44,23.5C44,34.27,35.05,43,24,43c-1.651,0-3.25-0.194-4.784-0.564	c-0.465-0.112-0.951-0.069-1.379,0.145L13.46,44.77C12.33,45.335,11,44.513,11,43.249v-4.025c0-0.575-0.257-1.111-0.681-1.499	C6.425,34.165,4,29.11,4,23.5C4,12.73,12.95,4,24,4S44,12.73,44,23.5z"
                              ></path>
                              <path
                                d="M34.992,17.292c-0.428,0-0.843,0.142-1.2,0.411l-5.694,4.215	c-0.133,0.1-0.28,0.15-0.435,0.15c-0.15,0-0.291-0.047-0.41-0.136l-3.972-2.99c-0.808-0.601-1.76-0.918-2.757-0.918	c-1.576,0-3.025,0.791-3.876,2.116l-1.211,1.891l-4.12,6.695c-0.392,0.614-0.422,1.372-0.071,2.014	c0.358,0.654,1.034,1.06,1.764,1.06c0.428,0,0.843-0.142,1.2-0.411l5.694-4.215c0.133-0.1,0.28-0.15,0.435-0.15	c0.15,0,0.291,0.047,0.41,0.136l3.972,2.99c0.809,0.602,1.76,0.918,2.757,0.918c1.576,0,3.025-0.791,3.876-2.116l1.211-1.891	l4.12-6.695c0.392-0.614,0.422-1.372,0.071-2.014C36.398,17.698,35.722,17.292,34.992,17.292L34.992,17.292z"
                                opacity=".05"
                              ></path>
                              <path
                                d="M34.992,17.792c-0.319,0-0.63,0.107-0.899,0.31l-5.697,4.218	c-0.216,0.163-0.468,0.248-0.732,0.248c-0.259,0-0.504-0.082-0.71-0.236l-3.973-2.991c-0.719-0.535-1.568-0.817-2.457-0.817	c-1.405,0-2.696,0.705-3.455,1.887l-1.21,1.891l-4.115,6.688c-0.297,0.465-0.32,1.033-0.058,1.511c0.266,0.486,0.787,0.8,1.325,0.8	c0.319,0,0.63-0.107,0.899-0.31l5.697-4.218c0.216-0.163,0.468-0.248,0.732-0.248c0.259,0,0.504,0.082,0.71,0.236l3.973,2.991	c0.719,0.535,1.568,0.817,2.457,0.817c1.405,0,2.696-0.705,3.455-1.887l1.21-1.891l4.115-6.688c0.297-0.465,0.32-1.033,0.058-1.511	C36.051,18.106,35.531,17.792,34.992,17.792L34.992,17.792z"
                                opacity=".07"
                              ></path>
                              <path
                                fill="#fff"
                                d="M34.394,18.501l-5.7,4.22c-0.61,0.46-1.44,0.46-2.04,0.01L22.68,19.74	c-1.68-1.25-4.06-0.82-5.19,0.94l-1.21,1.89l-4.11,6.68c-0.6,0.94,0.55,2.01,1.44,1.34l5.7-4.22c0.61-0.46,1.44-0.46,2.04-0.01	l3.974,2.991c1.68,1.25,4.06,0.82,5.19-0.94l1.21-1.89l4.11-6.68C36.434,18.901,35.284,17.831,34.394,18.501z"
                              ></path>
                            </svg>
                          </Col>
                          <Col className="text-center">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              x="0px"
                              y="0px"
                              width="35"
                              height="35"
                              viewBox="0 0 50 50"
                            >
                              <path d="M 11 4 C 7.134 4 4 7.134 4 11 L 4 39 C 4 42.866 7.134 46 11 46 L 39 46 C 42.866 46 46 42.866 46 39 L 46 11 C 46 7.134 42.866 4 39 4 L 11 4 z M 13.085938 13 L 21.023438 13 L 26.660156 21.009766 L 33.5 13 L 36 13 L 27.789062 22.613281 L 37.914062 37 L 29.978516 37 L 23.4375 27.707031 L 15.5 37 L 13 37 L 22.308594 26.103516 L 13.085938 13 z M 16.914062 15 L 31.021484 35 L 34.085938 35 L 19.978516 15 L 16.914062 15 z"></path>
                            </svg>
                          </Col>
                          <Col className="text-center">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              x="0px"
                              y="0px"
                              width="35"
                              height="35"
                              viewBox="0 0 48 48"
                            >
                              <radialGradient
                                id="yOrnnhliCrdS2gy~4tD8ma_Xy10Jcu1L2Su_gr1"
                                cx="19.38"
                                cy="42.035"
                                r="44.899"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop offset="0" stopColor="#fd5"></stop>
                                <stop offset=".328" stopColor="#ff543f"></stop>
                                <stop offset=".348" stopColor="#fc5245"></stop>
                                <stop offset=".504" stopColor="#e64771"></stop>
                                <stop offset=".643" stopColor="#d53e91"></stop>
                                <stop offset=".761" stopColor="#cc39a4"></stop>
                                <stop offset=".841" stopColor="#c837ab"></stop>
                              </radialGradient>
                              <path
                                fill="url(#yOrnnhliCrdS2gy~4tD8ma_Xy10Jcu1L2Su_gr1)"
                                d="M34.017,41.99l-20,0.019c-4.4,0.004-8.003-3.592-8.008-7.992l-0.019-20	c-0.004-4.4,3.592-8.003,7.992-8.008l20-0.019c4.4-0.004,8.003,3.592,8.008,7.992l0.019,20	C42.014,38.383,38.417,41.986,34.017,41.99z"
                              ></path>
                              <radialGradient
                                id="yOrnnhliCrdS2gy~4tD8mb_Xy10Jcu1L2Su_gr2"
                                cx="11.786"
                                cy="5.54"
                                r="29.813"
                                gradientTransform="matrix(1 0 0 .6663 0 1.849)"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop offset="0" stopColor="#4168c9"></stop>
                                <stop
                                  offset=".999"
                                  stopColor="#4168c9"
                                  stopOpacity="0"
                                ></stop>
                              </radialGradient>
                              <path
                                fill="url(#yOrnnhliCrdS2gy~4tD8mb_Xy10Jcu1L2Su_gr2)"
                                d="M34.017,41.99l-20,0.019c-4.4,0.004-8.003-3.592-8.008-7.992l-0.019-20	c-0.004-4.4,3.592-8.003,7.992-8.008l20-0.019c4.4-0.004,8.003,3.592,8.008,7.992l0.019,20	C42.014,38.383,38.417,41.986,34.017,41.99z"
                              ></path>
                              <path
                                fill="#fff"
                                d="M24,31c-3.859,0-7-3.14-7-7s3.141-7,7-7s7,3.14,7,7S27.859,31,24,31z M24,19c-2.757,0-5,2.243-5,5	s2.243,5,5,5s5-2.243,5-5S26.757,19,24,19z"
                              ></path>
                              <circle
                                cx="31.5"
                                cy="16.5"
                                r="1.5"
                                fill="#fff"
                              ></circle>
                              <path
                                fill="#fff"
                                d="M30,37H18c-3.859,0-7-3.14-7-7V18c0-3.86,3.141-7,7-7h12c3.859,0,7,3.14,7,7v12	C37,33.86,33.859,37,30,37z M18,13c-2.757,0-5,2.243-5,5v12c0,2.757,2.243,5,5,5h12c2.757,0,5-2.243,5-5V18c0-2.757-2.243-5-5-5H18z"
                              ></path>
                            </svg>
                          </Col>
                          <Col className="text-center">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="35"
                              height="35"
                              fill="#0088cc" /* Azul característico de Telegram */
                              className="bi bi-telegram"
                              viewBox="0 0 17 17"
                            >
                              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.287 5.906q-1.168.486-4.666 2.01-.567.225-.595.442c-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294q.39.01.868-.32 3.269-2.206 3.374-2.23c.05-.012.12-.026.166.016s.042.12.037.141c-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8 8 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629q.14.092.27.187c.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.4 1.4 0 0 0-.013-.315.34.34 0 0 0-.114-.217.53.53 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09" />
                            </svg>
                          </Col>
                          <Col xs={12} md={12} className="text-center">
                            <p>Redes sociales ilimitadas</p>
                          </Col>
                        </Row>
                      ) : (
                        <p>{line.msg}</p>
                      )}
                    </Col>
                  )}
                  {line.parts?.length && line.showLine ? (
                    <Col xs={12} md={12}>
                      {line.parts[0].title == "Redes Sociales" && (
                        <Row className="mb-3">
                          <Col className="text-center">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              x="0px"
                              y="0px"
                              width="35"
                              height="35"
                              viewBox="0 0 48 48"
                            >
                              <linearGradient
                                id="k87TA_gnBJ8uBlK4qfs8ia_AltfLkFSP7XN_gr1"
                                x1="6.718"
                                x2="35.097"
                                y1="12.801"
                                y2="41.18"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop offset="0" stopColor="#dfe9f2"></stop>
                                <stop offset=".391" stopColor="#d6e0e9"></stop>
                                <stop offset="1" stopColor="#bfc8d1"></stop>
                              </linearGradient>
                              <path
                                fill="url(#k87TA_gnBJ8uBlK4qfs8ia_AltfLkFSP7XN_gr1)"
                                d="M37.848,9.86C34.073,6.083,29.052,4.002,23.709,4C12.693,4,3.727,12.962,3.722,23.979	c-0.001,3.367,0.849,6.685,2.461,9.622L3.598,43.04c-0.094,0.345,0.002,0.713,0.254,0.967c0.19,0.192,0.447,0.297,0.711,0.297	c0.085,0,0.17-0.011,0.254-0.033l9.687-2.54c2.828,1.468,5.998,2.243,9.197,2.244c11.024,0,19.99-8.963,19.995-19.98	C43.698,18.656,41.621,13.636,37.848,9.86z"
                              ></path>
                              <linearGradient
                                id="k87TA_gnBJ8uBlK4qfs8ib_AltfLkFSP7XN_gr2"
                                x1="15.389"
                                x2="28.863"
                                y1="10.726"
                                y2="39.265"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop offset="0" stopColor="#2ecc75"></stop>
                                <stop offset="1" stopColor="#0b964a"></stop>
                              </linearGradient>
                              <path
                                fill="url(#k87TA_gnBJ8uBlK4qfs8ib_AltfLkFSP7XN_gr2)"
                                d="M34.871,12.832c-2.98-2.982-6.941-4.625-11.157-4.626c-8.704,0-15.783,7.076-15.787,15.774	c-0.001,2.981,0.833,5.883,2.413,8.396l0.376,0.597l-1.595,5.821l5.973-1.566l0.577,0.342c2.422,1.438,5.2,2.198,8.032,2.199h0.006	c8.698,0,15.777-7.077,15.78-15.776C39.49,19.778,37.851,15.814,34.871,12.832z"
                              ></path>
                              <path
                                d="M28.893,33.879c-0.995,0-2.354-0.254-5.087-1.331c-3.06-1.208-6.066-3.83-8.464-7.384l-0.077-0.113	c-0.642-0.857-2.132-3.107-2.132-5.5c0-2.58,1.288-3.953,1.838-4.54l0.085-0.091C15.815,14.089,16.709,14,17.058,14	c0.369,0.004,0.682,0,0.953,0.012c0.654,0.026,1.399,0.215,1.936,1.409l0,0c0.25,0.558,0.676,1.605,1.009,2.426	c0.213,0.527,0.386,0.955,0.439,1.069c0.294,0.586,0.308,1.167,0.036,1.714l-0.065,0.133c-0.128,0.262-0.261,0.533-0.544,0.863	l-0.235,0.282c-0.162,0.197-0.325,0.393-0.47,0.545c0.389,0.641,1.206,1.856,2.331,2.86c1.394,1.241,2.588,1.76,3.229,2.039	c0.127,0.055,0.233,0.102,0.317,0.142c0.405-0.47,1.072-1.271,1.302-1.614c0.77-1.156,1.877-0.755,2.24-0.622	c0.569,0.206,3.323,1.576,3.35,1.589l0.255,0.125c0.419,0.203,0.813,0.394,1.062,0.808c0.395,0.661,0.176,2.073-0.193,3.105	c-0.534,1.503-2.828,2.805-4.054,2.915l-0.226,0.024C29.465,33.855,29.196,33.879,28.893,33.879z M17.216,16	c-0.14,0-0.385-0.058-0.686,0.27l-0.101,0.109c-0.453,0.483-1.297,1.383-1.297,3.172c0,1.843,1.326,3.757,1.732,4.3	c0.027,0.036,0.071,0.101,0.135,0.194c2.175,3.223,4.853,5.582,7.541,6.642c3.384,1.335,4.253,1.234,4.956,1.151l0.278-0.03	c0.609-0.055,2.122-0.951,2.351-1.594c0.209-0.585,0.276-1.087,0.287-1.374c-0.044-0.021-0.092-0.043-0.143-0.067l-0.283-0.139	c-0.637-0.32-2.779-1.366-3.131-1.495c-0.442,0.608-1.262,1.565-1.479,1.814c-0.407,0.467-1.127,0.909-2.229,0.354	c-0.066-0.033-0.156-0.071-0.268-0.12c-0.691-0.301-2.13-0.926-3.763-2.38c-1.469-1.311-2.474-2.904-2.838-3.529	c-0.445-0.761-0.322-1.495,0.366-2.18c0.12-0.12,0.257-0.291,0.397-0.46l0.262-0.314c0.118-0.137,0.161-0.226,0.267-0.441	l0.035-0.071c-0.092-0.204-0.278-0.659-0.502-1.213c-0.323-0.797-0.736-1.815-0.979-2.357v0c-0.065-0.144-0.114-0.215-0.138-0.245	c0.005,0.015-0.029,0.016-0.058,0.014C17.706,16,17.463,16,17.216,16z M32.407,28.615L32.407,28.615L32.407,28.615z M19.642,19.736	L19.642,19.736L19.642,19.736z"
                                opacity=".05"
                              ></path>
                              <path
                                d="M28.889,33.384c-0.846,0-2.155-0.22-4.899-1.302c-2.967-1.17-5.891-3.727-8.233-7.198l-0.087-0.128	c-0.616-0.822-2.037-2.962-2.037-5.206c0-2.382,1.193-3.654,1.703-4.198l0.089-0.096c0.625-0.683,1.351-0.756,1.634-0.756	c0.377,0.003,0.667,0,0.931,0.012c0.492,0.02,1.057,0.124,1.502,1.114l0,0c0.249,0.554,0.671,1.594,1.001,2.409	c0.225,0.555,0.405,1.002,0.452,1.097c0.082,0.165,0.338,0.674,0.039,1.275l-0.067,0.136c-0.125,0.255-0.233,0.476-0.475,0.758	L20.2,21.59c-0.173,0.21-0.346,0.419-0.496,0.569c-0.216,0.215-0.216,0.215-0.13,0.362c0.328,0.563,1.232,1.998,2.541,3.165	c1.453,1.295,2.696,1.834,3.363,2.124c0.144,0.062,0.259,0.113,0.344,0.156c0.293,0.146,0.323,0.116,0.427-0.002	c0.288-0.328,1.168-1.364,1.463-1.807c0.554-0.83,1.269-0.57,1.654-0.431c0.506,0.184,3.039,1.437,3.296,1.566l0.262,0.128	c0.38,0.184,0.68,0.329,0.852,0.614c0.254,0.426,0.149,1.603-0.235,2.681c-0.488,1.371-2.646,2.497-3.628,2.585l-0.239,0.026	C29.441,33.354,29.196,33.384,28.889,33.384z M17.201,15.5c-0.026,0-0.052,0-0.078,0c-0.183,0-0.595,0.031-0.962,0.432l-0.097,0.104	c-0.465,0.496-1.432,1.528-1.432,3.514c0,1.943,1.281,3.864,1.832,4.6c0.025,0.033,0.064,0.09,0.121,0.174	c2.231,3.306,4.991,5.73,7.772,6.828c3.505,1.382,4.445,1.271,5.197,1.183l0.267-0.029c0.693-0.062,2.451-1.013,2.776-1.925	c0.333-0.932,0.347-1.71,0.296-1.877c0.007,0.006-0.232-0.098-0.405-0.182l-0.276-0.136c-0.623-0.313-2.806-1.381-3.188-1.52	c-0.36-0.13-0.361-0.133-0.48,0.046c-0.349,0.521-1.32,1.657-1.542,1.91c-0.642,0.735-1.384,0.359-1.629,0.236	c-0.072-0.036-0.171-0.078-0.293-0.131c-0.668-0.291-2.057-0.895-3.63-2.296c-1.416-1.262-2.387-2.803-2.739-3.407	c-0.476-0.814,0.059-1.347,0.287-1.574c0.13-0.13,0.28-0.313,0.431-0.497l0.255-0.306c0.159-0.186,0.226-0.322,0.336-0.547	l0.07-0.143c0.049-0.098,0.058-0.189-0.04-0.383c-0.052-0.104-0.245-0.578-0.483-1.167c-0.326-0.803-0.741-1.829-0.987-2.374l0,0	c-0.229-0.509-0.363-0.515-0.632-0.525C17.717,15.5,17.461,15.5,17.201,15.5z"
                                opacity=".07"
                              ></path>
                              <path
                                fill="#fff"
                                fillRule="evenodd"
                                d="M19.035,15.831c-0.355-0.79-0.729-0.806-1.068-0.82	C17.69,14.999,17.374,15,17.058,15s-0.83,0.119-1.265,0.594c-0.435,0.475-1.661,1.622-1.661,3.956s1.7,4.59,1.937,4.906	c0.237,0.316,3.282,5.259,8.104,7.161c4.007,1.58,4.823,1.266,5.693,1.187c0.87-0.079,2.807-1.147,3.202-2.255	c0.395-1.108,0.395-2.057,0.277-2.255c-0.119-0.198-0.435-0.316-0.909-0.554s-2.807-1.385-3.242-1.543	c-0.435-0.158-0.751-0.237-1.068,0.238c-0.316,0.474-1.225,1.543-1.502,1.859c-0.277,0.317-0.554,0.357-1.028,0.119	c-0.474-0.238-2.002-0.738-3.815-2.354c-1.41-1.257-2.362-2.81-2.639-3.285c-0.277-0.474-0.03-0.731,0.208-0.968	c0.213-0.213,0.474-0.554,0.712-0.831c0.237-0.277,0.316-0.475,0.474-0.791c0.158-0.317,0.079-0.594-0.04-0.831	C20.379,19.115,19.457,16.769,19.035,15.831z"
                                clipRule="evenodd"
                              ></path>
                            </svg>
                          </Col>

                          <Col className="text-center">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              x="0px"
                              y="0px"
                              width="35"
                              height="35"
                              viewBox="0 0 48 48"
                            >
                              <path
                                fill="#29b6f6"
                                d="M24 4A20 20 0 1 0 24 44A20 20 0 1 0 24 4Z"
                              ></path>
                              <path
                                fill="#fff"
                                d="M33.95,15l-3.746,19.126c0,0-0.161,0.874-1.245,0.874c-0.576,0-0.873-0.274-0.873-0.274l-8.114-6.733 l-3.97-2.001l-5.095-1.355c0,0-0.907-0.262-0.907-1.012c0-0.625,0.933-0.923,0.933-0.923l21.316-8.468 c-0.001-0.001,0.651-0.235,1.126-0.234C33.667,14,34,14.125,34,14.5C34,14.75,33.95,15,33.95,15z"
                              ></path>
                              <path
                                fill="#b0bec5"
                                d="M23,30.505l-3.426,3.374c0,0-0.149,0.115-0.348,0.12c-0.069,0.002-0.143-0.009-0.219-0.043 l0.964-5.965L23,30.505z"
                              ></path>
                              <path
                                fill="#cfd8dc"
                                d="M29.897,18.196c-0.169-0.22-0.481-0.26-0.701-0.093L16,26c0,0,2.106,5.892,2.427,6.912 c0.322,1.021,0.58,1.045,0.58,1.045l0.964-5.965l9.832-9.096C30.023,18.729,30.064,18.416,29.897,18.196z"
                              ></path>
                            </svg>
                          </Col>
                          <Col className="text-center">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              x="0px"
                              y="0px"
                              width="35"
                              height="35"
                              viewBox="0 0 48 48"
                            >
                              <radialGradient
                                id="8O3wK6b5ASW2Wn6hRCB5xa_YFbzdUk7Q3F8_gr1"
                                cx="11.087"
                                cy="7.022"
                                r="47.612"
                                gradientTransform="matrix(1 0 0 -1 0 50)"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop offset="0" stopColor="#1292ff"></stop>
                                <stop offset=".079" stopColor="#2982ff"></stop>
                                <stop offset=".23" stopColor="#4e69ff"></stop>
                                <stop offset=".351" stopColor="#6559ff"></stop>
                                <stop offset=".428" stopColor="#6d53ff"></stop>
                                <stop offset=".754" stopColor="#df47aa"></stop>
                                <stop offset=".946" stopColor="#ff6257"></stop>
                              </radialGradient>
                              <path
                                fill="url(#8O3wK6b5ASW2Wn6hRCB5xa_YFbzdUk7Q3F8_gr1)"
                                d="M44,23.5C44,34.27,35.05,43,24,43c-1.651,0-3.25-0.194-4.784-0.564	c-0.465-0.112-0.951-0.069-1.379,0.145L13.46,44.77C12.33,45.335,11,44.513,11,43.249v-4.025c0-0.575-0.257-1.111-0.681-1.499	C6.425,34.165,4,29.11,4,23.5C4,12.73,12.95,4,24,4S44,12.73,44,23.5z"
                              ></path>
                              <path
                                d="M34.992,17.292c-0.428,0-0.843,0.142-1.2,0.411l-5.694,4.215	c-0.133,0.1-0.28,0.15-0.435,0.15c-0.15,0-0.291-0.047-0.41-0.136l-3.972-2.99c-0.808-0.601-1.76-0.918-2.757-0.918	c-1.576,0-3.025,0.791-3.876,2.116l-1.211,1.891l-4.12,6.695c-0.392,0.614-0.422,1.372-0.071,2.014	c0.358,0.654,1.034,1.06,1.764,1.06c0.428,0,0.843-0.142,1.2-0.411l5.694-4.215c0.133-0.1,0.28-0.15,0.435-0.15	c0.15,0,0.291,0.047,0.41,0.136l3.972,2.99c0.809,0.602,1.76,0.918,2.757,0.918c1.576,0,3.025-0.791,3.876-2.116l1.211-1.891	l4.12-6.695c0.392-0.614,0.422-1.372,0.071-2.014C36.398,17.698,35.722,17.292,34.992,17.292L34.992,17.292z"
                                opacity=".05"
                              ></path>
                              <path
                                d="M34.992,17.792c-0.319,0-0.63,0.107-0.899,0.31l-5.697,4.218	c-0.216,0.163-0.468,0.248-0.732,0.248c-0.259,0-0.504-0.082-0.71-0.236l-3.973-2.991c-0.719-0.535-1.568-0.817-2.457-0.817	c-1.405,0-2.696,0.705-3.455,1.887l-1.21,1.891l-4.115,6.688c-0.297,0.465-0.32,1.033-0.058,1.511c0.266,0.486,0.787,0.8,1.325,0.8	c0.319,0,0.63-0.107,0.899-0.31l5.697-4.218c0.216-0.163,0.468-0.248,0.732-0.248c0.259,0,0.504,0.082,0.71,0.236l3.973,2.991	c0.719,0.535,1.568,0.817,2.457,0.817c1.405,0,2.696-0.705,3.455-1.887l1.21-1.891l4.115-6.688c0.297-0.465,0.32-1.033,0.058-1.511	C36.051,18.106,35.531,17.792,34.992,17.792L34.992,17.792z"
                                opacity=".07"
                              ></path>
                              <path
                                fill="#fff"
                                d="M34.394,18.501l-5.7,4.22c-0.61,0.46-1.44,0.46-2.04,0.01L22.68,19.74	c-1.68-1.25-4.06-0.82-5.19,0.94l-1.21,1.89l-4.11,6.68c-0.6,0.94,0.55,2.01,1.44,1.34l5.7-4.22c0.61-0.46,1.44-0.46,2.04-0.01	l3.974,2.991c1.68,1.25,4.06,0.82,5.19-0.94l1.21-1.89l4.11-6.68C36.434,18.901,35.284,17.831,34.394,18.501z"
                              ></path>
                            </svg>
                          </Col>
                        </Row>
                      )}
                      {!isNational && (
                        <>
                          <Progress multi style={{ height: 30 }}>
                            <ProgressBarDetail
                              text={"Consumido"}
                              total={line.total}
                              value={line.used}
                              color={"#5c666e"}
                            />
                            {line.parts.map((part, partIndex) => (
                              <ProgressBarDetail
                                key={`part-${partIndex}`}
                                text={part.title}
                                total={line.total}
                                value={part.unused}
                                color={"#81d3f6"}
                              />
                            ))}
                          </Progress>
                          <p className="mb-3">
                            Disponible: {line.available} {line.availableUnit}
                          </p>
                        </>
                      )}
                    </Col>
                  ) : null}
                </Row>
              ))}
              <Row>
                <Col>
                  {isNational && (
                    <Progress multi style={{ height: 30 }}>
                      <ProgressBarDetail
                        text={"Llamadas y SMS Ilimitados"}
                        total={100}
                        value={100}
                        color={"#5c666e"}
                      />
                    </Progress>
                  )}
                </Col>
              </Row>
            </CardBody>
          </Card>
        ))}
      </Col>
    </Row>
  );

  return (
    <>
      {isFetchingNational && (
        <div className="d-flex justify-content-center">
          <Spinner className="text-primary" />
        </div>
      )}
      <Row className="d-flex justify-content-center">
        <Col xs={12} md={8} className="">
          {isMovilidad && (
            <div {...handlers}>
              <div className="d-flex justify-content-between mb-2">
                <Button
                  color="primary"
                  onClick={() => setVisibleGauge("national")}
                >
                  Nacional
                </Button>
                <Button
                  color="primary"
                  onClick={() => setVisibleGauge("international")}
                >
                  Internacional
                </Button>
              </div>
            </div>
          )}
        </Col>
      </Row>
      <Row className="d-flex justify-content-center">
        { (visibleGauge && (nationalCards?.length === 0 ||  internationalCards?.length === 0)) && (
          <Col xs={12} md={8} className="m-auto text-center">
            <p className="my-4">
              No tienes ningún paquete activo en tu línea. te invitamos a
              activar un paquete desde:{" "}
            </p>
            <a href="/recargas">Recargas</a>
          </Col>
        )}

        {visibleGauge === "national" ? (
          <Col xs={12} md={8} className="">
            {storedGaugeDataNational != undefined &&
              storedGaugeDataNational &&
              storedGaugeData(storedGaugeDataNational)}
          </Col>
        ) : (
          <Col xs={12} md={8} className="">
            {storedGaugeDataInternational != undefined &&
              storedGaugeDataInternational &&
              storedGaugeData(storedGaugeDataInternational)}
          </Col>
        )}
        {visibleGauge === "national" ? (
          <Col xs={12} md={8} className="">
            {storedGaugeDataNational != undefined &&
              storedGaugeDataNational &&
              renderVictoryPieAdjusted(storedGaugeDataNational)}
          </Col>
        ) : (
          <Col xs={12} md={8} className="">
            {storedGaugeDataInternational != undefined &&
              storedGaugeDataInternational &&
              renderVictoryPieAdjusted(storedGaugeDataInternational)}
          </Col>
        )}
        {visibleGauge === "national" ? (
          <Col xs={12} md={8} className="">
            {nationalCards != undefined &&
              nationalCards &&
              drawCard(nationalCards, 1)}
          </Col>
        ) : (
          <Col xs={12} md={8} className="">
            {internationalCards != undefined &&
              internationalCards &&
              drawCard(internationalCards)}
          </Col>
        )}
      </Row>
    </>
  );
};

export default Statistics;
