import PropTypes from "prop-types";
import React from "react";
import {
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  Container,
} from "reactstrap";

//redux

import { Link } from "react-router-dom";
import withRouter from "../../Components/Common/withRouter";

import logoLight from "@images/logo-light.png";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import axios from "axios";
import {config} from "../../config/config"
import { errorAlert, successAlert } from "../../helpers/alerts";
import { useState } from "react";
import { useLocation,useNavigate } from "react-router-dom";

const ActivateAccount = (props) => {
  document.title = "New Password | Infynit";
  const [isLoading, setIsLoading] = useState(false);
  const [successC, setSuccessC] = React.useState(false)

  const search = useLocation().search;
  const navigate = useNavigate();

  React.useEffect(() => {
    function setPid() {
      const ref = new URLSearchParams(search).get('ref');
      if (ref) {
        accept(ref)
      }else(
        navigate('/login')
      )
    }
    setPid();
  }, []);


const accept =async (ref)=>{
  setIsLoading(true);
  try {
    let response = await axios.post(
      `${config.BACKEND.URL}/app/validateToken?ref=${ref}`
    );
    setIsLoading(false);
    if (response && response.status == 200) {
      successAlert("Succes", response.result);
      setSuccessC(true);
      navigate('/login');
      return true;
    }

    // '/payment'
    return false;
  } catch (err) {
    setIsLoading(false);
    errorAlert("Error", "No fue posible realizar la accion");
    return false;
  }
};

  return (
    <ParticlesAuth>
      <div className="auth-page-content">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="text-center mt-sm-5 mb-4 text-white-50">
                <div>
                  <Link to="/" className="d-inline-block auth-logo">
                    <img src={logoLight} alt="" height="40" />
                  </Link>
                </div>
              </div>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
            <Card className="mt-4">
              <CardBody className="p-4">
                <div className="text-center mt-2">
                  <h5 className="text-primary">Activar cuenta</h5>
                </div>
                {!successC
                  ?
                    <Alert
                      className="border-0 alert-warning text-center mb-2 mx-2"
                      role="alert"
                    >Debes tener un link valido para activar tu cuenta
                    </Alert>

                  :null
              }
              </CardBody>
              </Card>
              <div className="mt-4 text-center pt-5">
                <p className="mb-0">
                  Ir a iniciar sesion{" "}
                  <Link
                    to="/login"
                    className="fw-semibold text-primary text-decoration-underline"
                  >
                    {" "}
                    Click here{" "}
                  </Link>{" "}
                </p>
              </div>

            </Col>
          </Row>
        </Container>
      </div>
    </ParticlesAuth>
  );
};

ActivateAccount.propTypes = {
  history: PropTypes.object,
};

export default withRouter(ActivateAccount);
