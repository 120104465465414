import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { initMercadoPago, initPaypal, initStripe,validateIntentStripe,suscriptionStripe, validatePaypal } from '../../helpers/fakebackend_helper';
import { useOrders } from './useOrders';
import { errorAlert, successAlert } from '../../helpers/alerts';

export const usePayments = () => {
  const queryClient = useQueryClient();
  const { setupOrder, orderDataQuery } = useOrders();
  const { data: orderData } = orderDataQuery;

  const invalidate = () => {
    queryClient.invalidateQueries({ queryKey: ['history'] });
    queryClient.invalidateQueries({ queryKey: ['subs'] });
    queryClient.invalidateQueries({ queryKey: ['gauge-data'] });
  };

  const showPaymentError = (errorStatus,objectError) => {
    if (errorStatus==405) {
      errorAlert('Pago fallido', 'Este método de pago está deshabilitado');
      return;
    }
    errorAlert('Pago fallido', objectError.message);
  };

  //stripe
  const useSuscriptionStripe = useMutation({
    mutationKey: 'stripe-suscription',
    mutationFn: async ({ productId, msisdn }) => {
      // La función `initStripe` debe retornar el objeto con clientSecret
      const response = await suscriptionStripe({ msisdn, offerid: productId });
      return response.data; // Suponiendo que el clientSecret está en response.data
    },
    onSuccess: () => {
      invalidate();
    },
    onError: (error) => showPaymentError(error?.request?.status, error)
  });

  const usePayStripe = useMutation({
    mutationKey: 'pay-stripe',
    mutationFn: async ({ productId, msisdn }) => {
      // La función `initStripe` debe retornar el objeto con clientSecret
      const response = await initStripe({ msisdn, offerid: productId });
      return response.data; // Suponiendo que el clientSecret está en response.data
    },
    onSuccess: () => {
      invalidate();
    },
    onError: (error) => showPaymentError(error?.request?.status, error)
  });

  const useStripeValidate = useMutation({
    mutationKey: 'stripe-validate',
    mutationFn: async ({ paymentIntent, paymentIntentClientSecret })=>{
      return await validateIntentStripe({ paymentIntent, paymentIntentClientSecret });
    },
    onSuccess: () => {
      invalidate();
    },
    onError: (error) => showPaymentError(error?.request?.status, error)
  });

  // Mercado Pago
  const usePayMercadoPago = useMutation({
    mutationKey: 'pay-mercado-pago',
    mutationFn: async ({ productId, msisdn }) => {
      const { orderId } = await setupOrder({ msisdn, productId, order: orderData?.order, products: orderData?.product });
      return initMercadoPago(orderId);
    },
    onSuccess: (response) => {
      if (response?.data?.url) {
        invalidate();
        window.location.replace(response.data.url);
      }
    },
    onError: (error) => showPaymentError(error?.request?.status)
  });

  // Paypal
  const usePaypal = useMutation({
    mutationKey: 'pay-paypal',
    mutationFn: async ({ productId, msisdn }) => {
      const { orderId } = await setupOrder({ msisdn, productId, order: orderData?.order, products: orderData?.product });
      return initPaypal(orderId);
    },
    onSuccess: (response) => {
      invalidate();
      if (response?.data?.approval_url) {
        const redirectLink = response.data.approval_url;
        window.location.replace(redirectLink);
      }
    },
    onError: (error) => showPaymentError(error?.request?.status)
  });

  const usePaypalValidate = ({ paymentId, payerId }) => useQuery({
    queryKey: ['paypal-validate', paymentId, payerId],
    queryFn: () => validatePaypal({ paymentId, payerId }),
    enabled: !!paymentId && !!payerId,
    refetchInterval: (response) => response?.state?.data?.status ? false : 10000,
    select: (response) => response.status,
    onSuccess: () => {
      invalidate();
    },
  });

  return { usePayStripe,useSuscriptionStripe,useStripeValidate, usePayMercadoPago, usePaypal, usePaypalValidate };
};
