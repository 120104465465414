import React from "react";
import {
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";

import CarouselCards from './../CarouselCards';

const StripeSuscriptionModal = ({ isOpen,onToggle,phone}) => {


  return (
    <div>
      <React.Fragment>
        <Modal
          isOpen={isOpen}
          toggle={() => {
            onToggle();
          }}
          centered
          className="mx-auto"
          style={{ maxWidth: "700px" }}
        >
          <ModalHeader className="modal-title d-flex justify-content-center">
          <Label>Suscripcion de pago recurrente para {phone}</Label>
          </ModalHeader>
          <ModalBody className="text-center p-2 mx-2">
          <CarouselCards currentMsisdn={phone} maxItems={2} isSuscription={true}/>
          </ModalBody>
        </Modal>
      </React.Fragment>
    </div>
  );
};

export default StripeSuscriptionModal;
