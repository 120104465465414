import React, { useEffect } from "react";
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Input,
  Label,
  Form,
  FormFeedback,
  Spinner,
} from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// action
import { registerUser, apiError, resetRegisterFlag } from "../../slices/thunks";

//redux
import { useSelector, useDispatch } from "react-redux";

import { Link, useNavigate } from "react-router-dom";

//import images
import logoLight from "@images/logo-dark.png";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import { createSelector } from "reselect";
import axios from "axios";
import { errorAlert, successAlert } from "../../helpers/alerts";
import { useState } from "react";
import { sendCreateAccountReq } from '../../helpers/fakebackend_helper';

const Register = () => {
  const history = useNavigate();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const register = async (payload) => {
    const formvalues = {
      name: payload.name,
      last_name: payload.last_name,
      email: payload.email,
      msisdn: payload.phone,
      password: payload.password,
      password_confirmation: payload.confirm_password,
      politicas: true,
    };
    try {
      setIsLoading(true);
      let response = await sendCreateAccountReq(formvalues)
      if (response && response.status) {
        successAlert("Se te ha enviado un email para validar tu correo electronico");
        setTimeout(() => history("/login"), 3000);
        return true;
      }

      setIsLoading(false);
    } catch (err) {
      errorAlert("Error", err);
      setIsLoading(false);

      return false;
    }
  };

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
      name: "",
      password: "",
      confirm_password: "",
      phone: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Por favor ingrese su email"),
      name: Yup.string().required("Por favor ingrese su nombre"),
      last_name: Yup.string().required("Por favor ingrese su apellido"),
      password: Yup.string().required("Por favor ingrese su contraseña"),
      confirm_password: Yup.string()
        .oneOf([Yup.ref("password")], "Las contraseñas no coinciden")
        .required("Por favor confirme su contraseña"),
      phone: Yup.string().required("Por favor ingrese su telefono"),
    }),
    onSubmit: (values) => {
      register(values);
    },
  });

  // Inside your component

  document.title = "Registro de Usuario | Infynit";

  return (
    <React.Fragment>
      <ParticlesAuth>
        <div className="auth-page-content">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="text-center mt-sm-5 mb-4 text-white-50">
                  <div>
                    <Link to="/" className="d-inline-block auth-logo">
                      <img src={logoLight} alt="" height="100" />
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="mt-4">
                  <CardBody className="p-4">
                    <div className="text-center mt-2">
                      <h5 className="text-primary">Registrate</h5>
                      <p className="text-muted">
                        Entra a la plataforma y contrata, es facil y rapido
                      </p>
                    </div>
                    <div className="p-2 mt-4">
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                        className="needs-validation"
                        action="#"
                      >
                        {/* {success && success ? (
                          <>
                            {toast("Your Redirect To Login Page...", {
                              position: "top-right",
                              hideProgressBar: false,
                              className: "bg-success text-white",
                              progress: undefined,
                              toastId: "",
                            })}
                            <ToastContainer autoClose={2000} limit={1} />
                            <Alert color="success">
                              El registro de usuario ha sido exitoso, en unos
                              momentos será redirigido a la página de Inicio...
                            </Alert>
                          </>
                        ) : null} */}
                        {/*
                        {error && error ? (
                          <Alert color="danger">
                            <div>
                              El email ya ha sido registrado, ingrese con uno
                              diferente...{" "}
                            </div>
                          </Alert>
                        ) : null} */}
                        <div className="mb-3">
                          <Label className="form-label">
                          Mi número celular infynit<span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="phone"
                            type="tel"
                            placeholder="Número de telefono"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.phone || ""}
                            invalid={
                              validation.touched.phone &&
                              validation.errors.phone
                                ? true
                                : false
                            }
                          />
                          {validation.touched.phone &&
                          validation.errors.phone ? (
                            <FormFeedback type="invalid">
                              <div>{validation.errors.phone}</div>
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <Label htmlFor="useremail" className="form-label">
                            Email <span className="text-danger">*</span>
                          </Label>
                          <Input
                            id="email"
                            name="email"
                            className="form-control"
                            placeholder="Ingrese email"
                            type="email"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ""}
                            invalid={
                              validation.touched.email &&
                              validation.errors.email
                                ? true
                                : false
                            }
                          />
                          {validation.touched.email &&
                          validation.errors.email ? (
                            <FormFeedback type="invalid">
                              <div>{validation.errors.email}</div>
                            </FormFeedback>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="name" className="form-label">
                            Nombre{" "}
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="name"
                            type="text"
                            placeholder="Ingrese su nombre"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.name || ""}
                            invalid={
                              validation.touched.name && validation.errors.name
                                ? true
                                : false
                            }
                          />
                          {validation.touched.name && validation.errors.name ? (
                            <FormFeedback type="invalid">
                              <div>{validation.errors.name}</div>
                            </FormFeedback>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="last_name" className="form-label">
                            Apellido{" "}
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="last_name"
                            type="text"
                            placeholder="Ingrese su apellido"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.last_name || ""}
                            invalid={
                              validation.touched.last_name && validation.errors.last_name
                                ? true
                                : false
                            }
                          />
                          {validation.touched.last_name && validation.errors.last_name ? (
                            <FormFeedback type="invalid">
                              <div>{validation.errors.last_name}</div>
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <Label htmlFor="userpassword" className="form-label">
                            Contraseña <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="password"
                            type="password"
                            placeholder="Ingrese contraseña"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.password || ""}
                            invalid={
                              validation.touched.password &&
                              validation.errors.password
                                ? true
                                : false
                            }
                          />
                          {validation.touched.password &&
                          validation.errors.password ? (
                            <FormFeedback type="invalid">
                              <div>{validation.errors.password}</div>
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-2">
                          <Label
                            htmlFor="confirmPassword"
                            className="form-label"
                          >
                            Confirmar contraseña{" "}
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="confirm_password"
                            type="password"
                            placeholder="Confirmar contraseña"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.confirm_password || ""}
                            invalid={
                              validation.touched.confirm_password &&
                              validation.errors.confirm_password
                                ? true
                                : false
                            }
                          />
                          {validation.touched.confirm_password &&
                          validation.errors.confirm_password ? (
                            <FormFeedback type="invalid">
                              <div>{validation.errors.confirm_password}</div>
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-4">
                          <p className="mb-0 fs-12 text-muted fst-italic">
                            Al registrarte en Infynit aceptas que estás de
                            acuerdo con nuestros
                            <Link
                              to="https://pandanetworks.mx/terminos-y-condiciones/"
                              target="_blank"
                              className="text-primary text-decoration-underline fst-normal fw-medium mx-2"
                            >
                              Términos y Condiciones
                            </Link>
                          </p>
                        </div>

                        <div className="mt-4">
                          <button
                            className="btn btn-success w-100"
                            type="submit"
                            disabled={isLoading}
                          >
                            {isLoading && <Spinner size="sm" />} Registrarse
                          </button>
                        </div>
                      </Form>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-4 text-center">
                  <p className="mb-0">
                    ¿Ya tiene una cuenta con nosotros?{" "}
                    <Link
                      to="/login"
                      className="fw-semibold text-primary text-decoration-underline"
                    >
                      {" "}
                      Inicie sesión{" "}
                    </Link>{" "}
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </ParticlesAuth>
    </React.Fragment>
  );
};

export default Register;
