import React, { useCallback, useState,useEffect } from "react";

import {
  Card,
  CardBody,
  Carousel,
  CarouselControl,
  CarouselIndicators,
  CarouselItem,
  Nav,
  NavItem,
  NavLink,
  Spinner,
} from "reactstrap";
import classnames from "classnames";
import ProductCards from "./ProductCards";
import { ProductsProvider } from "../../Components/Hooks/ProductsProvider";

const CarouselCards = ({currentMsisdn,maxItems=3,isSuscription=false}) => {
  const { getOfferings } = ProductsProvider();
  const { data: products, isFetching: loadingProducts }  = getOfferings(currentMsisdn);


  const tabsList = [
    { text: "Planes Xpress", value: "XPRESS" },
    { text: "Planes Mensuales", value: "MENSUAL" },
    { text: "Planes Anuales", value: "ANUAL" },
  ];


  const [pillTab, setPillTab] = useState(tabsList[0]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const [chunkedProducts, setChunkedProducts] = useState([]);

  const next = () => {
      if (animating) return;
      const nextIndex = activeIndex === chunkedProducts.length - 1 ? 0 : activeIndex + 1;
      setActiveIndex(nextIndex);
    };

    const previous = () => {
      if (animating) return;
      const nextIndex = activeIndex === 0 ? chunkedProducts.length - 1 : activeIndex - 1;
      setActiveIndex(nextIndex);
    };

    const goToIndex = (newIndex) => {
      if (animating) return;
      setActiveIndex(newIndex);
    };

    const togglePillTab = (tab) => {
      if (pillTab.value !== tab.value) {
        setPillTab(tab);
        setActiveIndex(0);
      }
    };


  const filterAndChunkProducts = useCallback((products, chunkSize) => {
    const filteredProducts = products.filter((e) => e.name.includes(pillTab.value));
    const chunkedData = [];
    for (let i = 0; i < filteredProducts.length; i += chunkSize) {
        chunkedData.push(filteredProducts.slice(i, i + chunkSize));
    }

    return chunkedData;
  }, [pillTab.value]);

  useEffect(() => {
    if (products?.length) {
      let chunkedData = []
      if (window.matchMedia("(max-width: 600px)").matches) {
        chunkedData = filterAndChunkProducts(products, 1);
      } else {
        chunkedData = filterAndChunkProducts(products, maxItems);
      }
      setChunkedProducts(chunkedData);
    }
  }, [pillTab, products, filterAndChunkProducts]);



  return (
  <>
  <Card>
  <CardBody>
    <Nav pills className="nav-justified mb-1 align-items-center">
      {tabsList.map((tab, index) => (
        <NavItem key={'recharge-item' + index}>
          <NavLink
            style={{ cursor: "pointer" }}
            className={classnames({
              active: pillTab.value === tab.value,
            })}
            onClick={() => {
              togglePillTab(tab);
            }}
          >
            {tab.text}
          </NavLink>
        </NavItem>
      ))}
    </Nav>
  </CardBody>
  </Card>

  { loadingProducts && <div className="d-flex justify-content-center"><Spinner /></div> }
  { !loadingProducts &&
  <Carousel activeIndex={activeIndex} next={next} previous={previous}>
    { chunkedProducts?.length > 1 && <CarouselIndicators
      items={chunkedProducts.map((_, index) => ({ key: 'chunkedProduct' + index }))}
      activeIndex={activeIndex}
      onClickHandler={goToIndex}
      style={{ color: "red", important: "true" }}
    />}

    {chunkedProducts.map((productChunk, index) => (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={'carousel-item' + index}
      >
        <div className="d-flex gap-4 justify-content-center py-4">
          {productChunk.map(product => <ProductCards isSuscription={isSuscription} e={product} key={'product-'+product.id} pillTab={pillTab} currentMsisdn={currentMsisdn}/> )}
        </div>
      </CarouselItem>
    ))}

    { chunkedProducts?.length > 1 && <CarouselControl
      direction="prev"
      directionText="Previous"
      onClickHandler={previous}
    /> }
    { chunkedProducts?.length > 1 && <CarouselControl
      direction="next"
      directionText="Next"
      onClickHandler={next}
    /> }
  </Carousel>
  }
  </>
  );
};


export default CarouselCards;
