import React from "react";
import {
  Button,
  Card,
  Col,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import Paypal from "../../assets/images/payments/paypal.png";
import MercadoPago from "../../assets/images/payments/MercadoPago.svg";
import { useLoggedIn } from "../../Components/Hooks/useLoggedIn";
import { authProvider } from "../../Components/Hooks/authProvider";
import { useEffect, useState } from "react";
import SpinnerCool from "../../Components/Common/Spinner/Spinner";
import StripePayment from "./Stripe/StripePayment";
import { usePayments } from "./usePayments";
import socialNetworkImage from "@/assets/images/socialNetwork.png";
import check from "@/assets/images/check.png";
import StripeSuscription from "./Stripe/StripeSuscription";

const ModalPayments = ({
  isOpen,
  onToggle,
  e,
  currentMsisdn,
  isSuscription = false,
}) => {
  const { user } = useLoggedIn();
  const { phones } = authProvider();

  const { usePayMercadoPago, usePaypal } = usePayments();
  const {
    mutate: payMercadoPago,
    isPending: loadingMP,
    isSuccess: successMP,
  } = usePayMercadoPago;
  const {
    mutate: payPaypal,
    isPending: loadingPaypal,
    isSuccess: successPaypal,
  } = usePaypal;
  const [isChecked, setIsChecked] = useState(false);
  const [showStripeFields, setShowStripeFields] = useState(false);
  const [stripePaymentProcess, setStripePaymentProcess] = useState(false);

  const handleMercadoPagoPay = () => {
    payMercadoPago({ productId: e.id, msisdn: currentMsisdn });
  };

  const handlePaypalPay = () => {
    payPaypal({ productId: e.id, msisdn: currentMsisdn });
  };

  const [planDetails, setPlanDetails] = useState(null);

  useEffect(() => {
    const parsedData = parseHtmlData(e.description);
    setPlanDetails(parsedData);
  }, []);

  const decodeHtmlEntities = (str) => {
    const entityMap = {
      "&nbsp;": " ",
      "&amp;": "&",
      "&lt;": "<",
      "&gt;": ">",
      "&quot;": '"',
      "&#39;": "'",
    };

    return str.replace(
      /&[a-zA-Z0-9#]+;/g,
      (entity) => entityMap[entity] || entity
    );
  };

  const stripHtmlAndDecode = (html) => {
    return decodeHtmlEntities(html.replace(/<\/?[^>]+(>|$)/g, "").trim());
  };

  const parseHtmlData = (html) => {
    const tiempoMatch = html.match(/tiempo:(.*?)<\/p>/)?.[1] || "";
    const gbMatch = html.match(/gb:(.*?)<\/p>/)?.[1] || "";
    const rsMatch = html.match(/rs:(.*?)<\/p>/)?.[1] || "";
    const minMatch = html.match(/min:(.*?)<\/p>/)?.[1] || "";
    const comMatch = html.match(/com:(.*?)<\/p>/)?.[1] || "";
    const tycMatch = html.match(/tyc:(.*?)<\/p>/)?.[1] || "";

    return {
      planDuration: stripHtmlAndDecode(tiempoMatch),
      gb: stripHtmlAndDecode(gbMatch),
      rs: stripHtmlAndDecode(rsMatch),
      min: stripHtmlAndDecode(minMatch),
      com: stripHtmlAndDecode(comMatch),
      tyc: tycMatch.trim(),
    };
  };
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };
  return (
    <div>
      <React.Fragment>
        <Modal
          isOpen={isOpen}
          toggle={() => {
            onToggle();
          }}
          centered
          className="mx-auto"
          style={{ maxWidth: "700px" }}
        >
          <ModalHeader className="modal-title d-flex justify-content-center">
            {/* <h5 className="text-primary">Medio de pago</h5> */}
          </ModalHeader>
          <ModalBody className="text-center p-2 mx-2">
            <Row className="justify-content-center d-flex align-items-center">
              <SpinnerCool isLoading={stripePaymentProcess} />
              <Col md={6}>
                <Row className="justify-content-center">
                  <Card className="text-center" style={{ width: "330px" }}>
                    <div
                      style={{
                        flex: 1,
                        background:
                          "linear-gradient(-60deg, #000000 50%, #d0d0d0 100%)",
                        borderRadius: "30px",
                        padding: "16px",
                      }}
                    >
                      <div style={{ textAlign: "center" }}>
                        <h2
                          style={{
                            color: "white",
                            fontSize: "20px",
                            fontWeight: "bold",
                          }}
                        >
                          {e.name}
                        </h2>
                        {planDetails && (
                          <div
                            style={{ textAlign: "center", paddingTop: "8px" }}
                          >
                            <p
                              style={{
                                fontSize: "40px",
                                fontWeight: "bold",
                                color: "#81d3f6",
                              }}
                            >
                              ${e.amount}
                            </p>
                            <p style={{ fontSize: "20px", color: "white" }}>
                              {planDetails.gb} por {planDetails.planDuration}
                              <br />
                              para navegar.
                            </p>
                            <p style={{ fontSize: "15px", color: "white" }}>
                              {planDetails.com}
                            </p>
                            <p
                              style={{
                                fontSize: "17px",
                                color: "white",
                                textAlign: "right",
                              }}
                            >
                              {planDetails.rs}
                            </p>
                            <div
                              style={{
                                position: "relative",
                                width: "100%",
                                height: "60px",
                              }}
                            >
                              <img
                                src={socialNetworkImage} // Ensure this path is correct
                                alt="social"
                                style={{
                                  position: "absolute",
                                  right: "-10px",
                                  top: "0",
                                  transform: "translateY(-15px)",
                                  maxWidth: "100%", // Allow image to scale based on the container width
                                  height: "auto", // Maintain aspect ratio
                                }}
                              />
                            </div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                paddingTop: "24px",
                              }}
                            >
                              <img
                                src={check}
                                alt="check"
                                style={{
                                  width: "25px",
                                  height: "25px",
                                  marginRight: "8px",
                                }}
                              />
                              <p
                                style={{
                                  fontSize: "15px",
                                  fontWeight: "bold",
                                  color: "white",
                                }}
                              >
                                {planDetails.min}
                              </p>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                padding: "8px 0 16px",
                                width: "100%",
                              }}
                            ></div>
                          </div>
                        )}
                      </div>
                    </div>
                  </Card>
                </Row>
              </Col>

              <Col md={6}>
                <Row className="justify-content-center">
                  <Col xs={10}>
                    <Label className="text-primary">
                      Número {currentMsisdn}
                    </Label>
                  </Col>

                  <Col className="col-12 mt-4 text-primary">
                  {isSuscription?'Suscribir'
                  :
                    'Elige pagar con:'
                  }
                  </Col>

                  {!isSuscription && !showStripeFields && <>
                      <button
                        className="mt-4 cursor-pointer"
                        type="button"
                        disabled={loadingPaypal || successPaypal}
                        style={{
                          background: "#ffc439",
                          width: "70%",
                          borderRadius: "4px",
                          border: "none",
                          height: "40px",
                        }}
                        onClick={handlePaypalPay}
                      >
                        {loadingPaypal ? (
                          <Spinner color="primary" />
                        ) : (
                          <img src={Paypal} height="100%" />
                        )}
                      </button>

                      <button
                        className="mt-4 cursor-pointer"
                        type="button"
                        disabled={loadingMP || successMP}
                        style={{
                          background: "#a8e5ff",
                          width: "70%",
                          borderRadius: "4px",
                          border: "none",
                          height: "40px",
                          padding: "7px",
                        }}
                        onClick={handleMercadoPagoPay}
                      >
                        {loadingMP ? (
                          <Spinner color="primary" />
                        ) : (
                          <img src={MercadoPago} height="100%" />
                        )}
                      </button>
                  </>}

                  {!isSuscription && (
                    <button
                      className="mt-4 cursor-pointer"
                      type="button"
                      style={{
                        background: "#645aff",
                        width: "70%",
                        borderRadius: "4px",
                        border: "none",
                        height: "40px",
                        padding: "7px",
                        color: "white",
                      }}
                      onClick={() => setShowStripeFields(!showStripeFields)}
                    >
                      {showStripeFields ? "Regresar" : "Pago con tarjeta"}
                    </button>
                  )}
                  {!isSuscription && (
                    <div className="form-group">
                      <input
                        type="checkbox"
                        id="saveCard"
                        name="saveCard"
                        checked={isChecked}
                        onChange={handleCheckboxChange}
                        className="mx-2"
                      />
                      <label htmlFor="saveCard">
                        Guardar tarjeta pago recurrente
                      </label>
                    </div>
                  )}

                  {!isSuscription &&
                    showStripeFields &&
                    showStripeFields &&
                    !isChecked && (
                      <StripePayment msisdn={currentMsisdn} productId={e.id} />
                    )}
                  {showStripeFields && isChecked && (
                    <StripeSuscription
                      msisdn={currentMsisdn}
                      productId={e.id}
                    />
                  )}
                  {isSuscription && (
                    <StripeSuscription
                      msisdn={currentMsisdn}
                      productId={e.id}
                    />
                  )}
                </Row>
              </Col>
              <Col xs={6} sm={5}>
                <Button
                  disabled={stripePaymentProcess}
                  color="primary"
                  outline
                  onClick={onToggle}
                  className="w-100 my-4"
                >
                  Cerrar
                </Button>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      </React.Fragment>
    </div>
  );
};

export default ModalPayments;
